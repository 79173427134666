import axios from './Axios';
import { getConfig } from '../../AppConfig';

const path = '/gift-certificate';
const config = getConfig();

export const getGCTemplates = () =>
  axios.get(`${path}/templates/all`, {
    params: {
      client: config.profileKey,
    },
  });

export const purchaseGiftCertificates = (data) =>
  axios.post(`${path}/buy`, {
    ...data,
    client: config.profileKey,
  });

export const redeemGiftCertificates = (code) =>
  axios.post(`${path}/redeem`, { code });

export const getGCAvailable = (checkoutCode) =>
  axios.get(`${path}/available-balance/${checkoutCode}`);

export const getCheckoutData = (checkoutCode) =>
  axios.get(`${path}/order/tracking/${checkoutCode}`);

export default {
  getGCTemplates,
  purchaseGiftCertificates,
  getCheckoutData,
};
