import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  giftCartDetail: [],
  templatesData: {
    loading: false,
    data: [],
  },
  purchaseData: {
    loading: false,
    data: {},
  },
  checkoutData: {
    loading: false,
    data: {},
  },
  redeemData: {
    loading: false,
    data: {},
  },
  availableData: {
    loading: false,
    trigger: false,
    data: {},
  },
};

const giftSlice = createSlice({
  name: 'gift',
  initialState: INITIAL_STATE,
  reducers: {
    setGiftCartDetail(state, { payload }) {
      return {
        ...state,
        giftCartDetail: payload,
      };
    },
    getTemplatesRequest(state) {
      return {
        ...state,
        templatesData: {
          loading: true,
          data: [],
        },
      };
    },
    getTemplatesSuccess(state, { payload }) {
      return {
        ...state,
        templatesData: {
          loading: false,
          data: payload,
        },
      };
    },
    getTemplatesFail(state) {
      return {
        ...state,
        templatesData: {
          loading: false,
          data: [],
        },
      };
    },
    purchaseGiftCertificatesRequest(state) {
      return {
        ...state,
        purchaseData: {
          loading: true,
          data: [],
        },
      };
    },
    purchaseGiftCertificatesSuccess(state, { payload }) {
      return {
        ...state,
        purchaseData: {
          loading: false,
          data: payload,
        },
      };
    },
    purchaseGiftCertificatesFail(state) {
      return {
        ...state,
        purchaseData: {
          loading: false,
          data: [],
        },
      };
    },
    resetPurchaseData(state) {
      return {
        ...state,
        purchaseData: {
          loading: false,
          data: [],
        },
      };
    },
    getCheckoutDataRequest(state) {
      return {
        ...state,
        checkoutData: {
          loading: true,
          data: [],
        },
      };
    },
    getCheckoutDataSuccess(state, { payload }) {
      return {
        ...state,
        checkoutData: {
          loading: false,
          data: payload,
        },
      };
    },
    getCheckoutDataFail(state) {
      return {
        ...state,
        checkoutData: {
          loading: false,
          data: [],
        },
      };
    },
    redeemGCRequest(state) {
      return {
        ...state,
        redeemData: {
          loading: true,
          data: {},
        },
      };
    },
    redeemGCSuccess(state, { payload }) {
      return {
        ...state,
        redeemData: {
          loading: false,
          data: payload,
        },
      };
    },
    redeemGCFail(state) {
      return {
        ...state,
        redeemData: {
          loading: false,
          data: [],
        },
      };
    },
    getGCAvailableRequest(state) {
      return {
        ...state,
        availableData: {
          loading: true,
          trigger: false,
          data: {},
        },
      };
    },
    getGCAvailableSuccess(state, { payload }) {
      return {
        ...state,
        availableData: {
          loading: false,
          trigger: false,
          data: payload,
        },
      };
    },
    getGCAvailableFail(state) {
      return {
        ...state,
        availableData: {
          loading: false,
          trigger: false,
          data: [],
        },
      };
    },
    setGCAvailableTrigger(state, { payload }) {
      return {
        ...state,
        availableData: {
          ...state.availableData,
          trigger: payload,
        },
      };
    },
  },
});

export const {
  setGiftCartDetail,
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplatesFail,
  purchaseGiftCertificatesRequest,
  purchaseGiftCertificatesSuccess,
  purchaseGiftCertificatesFail,
  resetPurchaseData,
  getCheckoutDataRequest,
  getCheckoutDataSuccess,
  getCheckoutDataFail,
  redeemGCRequest,
  redeemGCSuccess,
  redeemGCFail,
  getGCAvailableRequest,
  getGCAvailableSuccess,
  getGCAvailableFail,
  setGCAvailableTrigger,
} = giftSlice.actions;

export default giftSlice.reducer;
